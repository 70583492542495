import React, { FC, useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { DetailedTableRecord } from 'api/src/common/baserowImporterTypes'
import {
  FindAirtableToBaserowRequest,
  FindAirtableToBaserowRequestVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import BaserowImporterSectionLeftTableMenu from 'src/components/BaserowImporter/BaserowImporterSectionLeftTableMenu'
import BaserowImporterSectionRight from 'src/components/BaserowImporter/BaserowImporterSectionRight'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindAirtableToBaserowRequest($id: Int!) {
    # This part will be removed at cleanup
    airtableToBaserowRequest(id: $id) {
      id
      client {
        id
        name
      }
      createdByUser {
        id
        name
        avatarUrl
      }
      updatedByUser {
        id
        name
        avatarUrl
      }
      createdAt
      updatedAt
      airtableBaseId
      airtableShareId
      baserowBaseId
      migrateComments
      migrateLinks
      migrateViews
      migrateFields
      migrateRecords
      migrateTables
      maxMigrationRows
      status
      title
      baserowSchema
      migrationData {
        id
        name
        recordId
        primaryFieldId
        matchingTable
        migrationOutcome
        migrationMessage
        fields {
          id
          name
          recordId
          type
          details
          options
          matchingField
          migrationOutcome
          migrationMessage
        }
        views {
          id
          name
          recordId
          type
          details
          matchingView
          migrationOutcome
          migrationMessage
        }
      }
    }
  }
`
type SuccessProps = CellSuccessProps<
  FindAirtableToBaserowRequest,
  FindAirtableToBaserowRequestVariables
>

export const Empty = () => (
  <BaserowImporterSectionLeftTableMenu
    sortedTableListData={[]}
    setSelectedTableIndex={null}
    selectedTableIndex={0}
    migrationRequest={null}
  />
)

export type CellMigrationType = SuccessProps['airtableToBaserowRequest']

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Loading = () => <LoadingSpinner />

export const Success: FC<SuccessProps> = ({ airtableToBaserowRequest }) => {
  const [selectedTableIndex, setSelectedTableIndex] = useState(0)
  const [detailedTableData, setDetailedTableData] =
    useState<DetailedTableRecord[]>()

  const sortedDetailedTableData: DetailedTableRecord[] = useMemo(() => {
    const sortedData: DetailedTableRecord[] = detailedTableData
      ?.slice()
      .sort((a, b) => a.name.localeCompare(b.name))
    return sortedData
  }, [detailedTableData]) // Dependency array to recompute only if enrichedData changes

  useEffect(() => {
    setDetailedTableData(
      airtableToBaserowRequest?.migrationData as DetailedTableRecord[],
    )
  }, [airtableToBaserowRequest])
  return (
    <Stack direction={'row'} spacing={2} className={'p-3'}>
      <Box className={'bg-white border-2 p-3 w-[25%]'}>
        <BaserowImporterSectionLeftTableMenu
          sortedTableListData={sortedDetailedTableData}
          setSelectedTableIndex={setSelectedTableIndex}
          selectedTableIndex={selectedTableIndex}
          migrationRequest={airtableToBaserowRequest}
        />
      </Box>
      <Box className={'bg-white border-2 p-3 w-[75%]'}>
        <BaserowImporterSectionRight
          sortedDetailedTableData={sortedDetailedTableData}
          setDetailedTableData={setDetailedTableData}
          selectedTableIndex={selectedTableIndex}
          selectedRequest={airtableToBaserowRequest}
        />
      </Box>
    </Stack>
  )
}
