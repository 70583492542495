import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DetailedViewRecord } from 'api/src/common/baserowImporterTypes'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewGrouping = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Accordion>
      <AccordionSummary
        className="border border-gray-300 border-solid rounded"
        expandIcon={<ChevronDownIcon className="w-5 h-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Grouping</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} className={'border p-2'}>
          {enrichedData?.details?.groupLevels?.map((group, index) => {
            return (
              <Stack key={index} spacing={1} direction={'row'}>
                <Typography>Field</Typography>
                <Chip
                  size="small"
                  className="rounded text-sm bg-blue-100 text-blue-500 px-2 w-[20%] "
                  label={group.name}
                />
                <Typography>Sorted</Typography>
                <Chip
                  size="small"
                  className="capitalize rounded text-sm bg-violet-100 text-violet-500 px-2 w-[20%] "
                  label={group.order}
                />
                <Typography>While</Typography>
                <Chip
                  size="small"
                  className="capitalize rounded text-sm bg-emerald-100 text-emerald-500 px-2 w-[20%] "
                  label={
                    group.emptyGroupState === 'hidden' ? 'Hiding' : 'Showing'
                  }
                />
                <Typography>Empty Groups</Typography>
              </Stack>
            )
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewGrouping
