import { FC } from 'react'

import {
  FindAirtableToBaserowImporterLogData,
  FindAirtableToBaserowImporterLogDataVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import BaserowImporterModalRequestLog from 'src/components/BaserowImporter/BaserowImporterModalRequestLog'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindAirtableToBaserowImporterLogData($requestId: Int!) {
    airtableToBaserowMigrationLogByRequest(requestId: $requestId) {
      id
      createdAt
      entityType
      entityName
      logMessage
      logType
    }
  }
`
type SuccessProps = CellSuccessProps<
  FindAirtableToBaserowImporterLogData,
  FindAirtableToBaserowImporterLogDataVariables
> & {
  openModal: boolean
  handleCloseModal: () => void
}

export type CellLogType =
  SuccessProps['airtableToBaserowMigrationLogByRequest'][0]
export type RefetchQuery = SuccessProps['queryResult']['refetch']

export const Empty = ({
  queryResult: { refetch },
  openModal,
  handleCloseModal,
}) => (
  <BaserowImporterModalRequestLog
    logData={[]}
    refetchQuery={refetch}
    openModal={openModal}
    handleCloseModal={handleCloseModal}
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Loading = () => <LoadingSpinner />

export const Success: FC<SuccessProps> = ({
  airtableToBaserowMigrationLogByRequest,
  queryResult: { refetch },
  openModal,
  handleCloseModal,
}) => {
  return (
    <BaserowImporterModalRequestLog
      logData={airtableToBaserowMigrationLogByRequest}
      refetchQuery={refetch}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
    />
  )
}
