import { FC } from 'react'

import {
  FindAirtableToBaserowImporterData,
  FindAirtableToBaserowImporterDataVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import BaserowImporterPageMain from '../BaserowImporterPageMain'

export const QUERY = gql`
  query FindAirtableToBaserowImporterData {
    # This part will be removed at cleanup
    airtableToBaserowRequests {
      id
      client {
        id
        name
      }
      createdByUser {
        id
        name
        avatarUrl
      }
      updatedByUser {
        id
        name
        avatarUrl
      }
      createdAt
      updatedAt
      airtableBaseId
      airtableShareId
      baserowBaseId
      status
      title
    }
  }
`
type SuccessProps = CellSuccessProps<
  FindAirtableToBaserowImporterData,
  FindAirtableToBaserowImporterDataVariables
>

export const Empty = ({ queryResult: { refetch } }) => (
  <BaserowImporterPageMain importRequests={[]} refetchQuery={refetch} />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Loading = () => <LoadingSpinner />

export type RefetchQuery = SuccessProps['queryResult']['refetch']

export const Success: FC<SuccessProps> = ({
  airtableToBaserowRequests,
  queryResult: { refetch },
}) => {
  return (
    <BaserowImporterPageMain
      importRequests={airtableToBaserowRequests}
      refetchQuery={refetch}
    />
  )
}
