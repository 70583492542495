import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { Stack, TextField } from '@mui/material'
import {
  createBaserowImportRequest,
  createBaserowImportRequestVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { SUBMIT_IMPORTER_REQUEST } from 'src/components/BaserowImporter/utils'
import Modal from 'src/components/Modal/Modal'

interface BaserowImporterCreateModalProps {
  openModal: boolean
  handleCloseModal: () => void
}
const BaserowImporterModalCreateRequest = ({
  openModal,
  handleCloseModal,
}: BaserowImporterCreateModalProps) => {
  const [airtableBaseId, setAirtableBaseId] = useState('')
  const [airtableSharedId, setAirtableSharedId] = useState('')
  const [baserowBaseId, setBaserowBaseId] = useState('')
  const [title, setTitle] = useState('New Request')

  const [submitting, setSubmitting] = useState(false)

  const handleSubmitJob = async () => {
    setSubmitting(true)

    if (!title) {
      toast.error('Please enter a title')
      setSubmitting(false)
      return
    }

    if (!baserowBaseId) {
      toast.error('Please enter a Baserow Base ID')
      setSubmitting(false)
      return
    }

    if (!airtableBaseId) {
      toast.error('Please enter an Airtable Base ID')
      setSubmitting(false)
      return
    }

    if (!airtableSharedId) {
      toast.error('Please enter an Airtable Share ID')
      setSubmitting(false)
      return
    }

    await createJobRequest({
      variables: {
        input: {
          title: title,
          baserowBaseId: baserowBaseId,
          airtableBaseId: airtableBaseId,
          airtableShareId: airtableSharedId,
        },
      },
    })
    setSubmitting(false)
    handleCloseModal()
  }

  const [createJobRequest] = useMutation<
    createBaserowImportRequest,
    createBaserowImportRequestVariables
  >(SUBMIT_IMPORTER_REQUEST, {
    onCompleted: (_data) => {
      toast.success('Job submitted successfully')
    },
    refetchQueries: [
      'FindAirtableToBaserowImporterData',
      'FindAirtableToBaserowRequest',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      title={'Create New Import Request'}
      footerVisible={true}
      onConfirm={handleSubmitJob}
      onCancel={handleCloseModal}
      confirmText={'Submit'}
      loading={submitting}
      className="max-w-2xl"
    >
      <Stack
        spacing={3}
        className={'mb-5 p-3 w-full bg-white flex justify-between'}
      >
        <TextField
          size={'small'}
          label="Request Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          size={'small'}
          label="Airtable Base ID"
          value={airtableBaseId}
          onChange={(e) => setAirtableBaseId(e.target.value)}
        />
        <TextField
          size={'small'}
          label="Airtable Share Link ID"
          value={airtableSharedId}
          onChange={(e) => setAirtableSharedId(e.target.value)}
        />
        <TextField
          size={'small'}
          label="Baserow Base ID"
          value={baserowBaseId}
          onChange={(e) => setBaserowBaseId(e.target.value)}
        />
      </Stack>
    </Modal>
  )
}

export default BaserowImporterModalCreateRequest
