import {
  AirtableToBaserowMigrationField,
  AirtableToBaserowMigrationTable,
  AirtableToBaserowMigrationView,
} from 'types/graphql'
import { FindAirtableToBaserowImporterData } from 'web/types/graphql'

export enum BASEROW_IMPORT_MATCH_TYPE {
  UNMATCHED = 'unmatched',
  DETECTED = 'detected',
  MANUAL = 'manual',
  CREATE = 'create',
  IGNORE = 'ignore',
}

// Types for everything. Keep this one perfect and nothing else should be needed. We grab the db view but then define
// the JSON types by extending the db types and calling the new types "Detailed...."
export type DetailedViewRecord = AirtableToBaserowMigrationView & {
  details?: ViewDetails
  matchingView?: MatchingBaserowViewType
}
export type DetailedFieldRecord = AirtableToBaserowMigrationField & {
  details?: Record<string, any> // This contains a lot of different information depending on field types. I'm not defining it here
  options?: Record<string, any> // Same as above
  matchingField?: MatchingBaserowFieldType
  migrationOutputRecordId?: string
}

export type DetailedTableRecord = AirtableToBaserowMigrationTable & {
  fields: DetailedFieldRecord[]
  views: DetailedViewRecord[]
  matchingTable?: MatchingBaserowTableType
  migrationOutputRecordId?: string
}

export type CellBaserowRequest =
  FindAirtableToBaserowImporterData['airtableToBaserowRequests'][0]

export type JSONResultObject = {
  error: string
  formula: string
  confidence: number
  description: string
}

// Now define the details and options. This is super long apologies if you're reading this
export type FilterSetItem = {
  id: string
  name: string
  recordId: string
  type: string
  details: {
    id: string
    name: string
    type: string
    lastModifiedTime: string
    initialCreatedTime: string
    lastModifiedByUserId: string
    initialCreatedByUserId: string
  }
  options: any // Define more specifically if possible
  matchingField?: {
    id: number
    name: string
    type: string
    order: number
    primary: boolean
    table_id: number
    matchType: string
    read_only: boolean
    text_default: string
  }
  value: any
  columnId: string
  operator: string
  conjunction: string
  filterSet?: FilterSetItem[]
}

type Filters = {
  filterSet: FilterSetItem[]
  conjunction: string
}

type ColumnOrderItem = {
  id: string
  name: string
  recordId: string
  type: string
  details: {
    id: string
    name: string
    type: string
    lastModifiedTime: string
    initialCreatedTime: string
    lastModifiedByUserId: string
    initialCreatedByUserId: string
  }
  options: any // Define more specifically if possible
  matchingField?: any
  forceCreate: any
  columnId: string
  visibility: boolean
  ascending: boolean
}

type GroupLevelItem = {
  id: string
  name: string
  recordId: string
  type: string
  details: {
    id: string
    name: string
    type: string
    lastModifiedTime: string
    initialCreatedTime: string
    lastModifiedByUserId: string
    initialCreatedByUserId: string
  }
  options: any // Define more specifically if possible
  matchingField?: any
  forceCreate: any
  order: string
  columnId: string
  emptyGroupState: string
}

type ColorConfig = {
  type: string
  defaultColor?: string
  colorDefinitions: {
    id: string
    color: string
    filterSet: FilterSetItem[]
    conjunction: string
  }[]
}

export type ViewDetails = {
  columnOrder: ColumnOrderItem[]
  filters: Filters
  colorConfig: ColorConfig
  groupLevels: GroupLevelItem[]
  lastSortsApplied: {
    sortSet: ColumnOrderItem[]
    appliedTime: string
    shouldAutoSort: boolean
  }
  frozenColumnCount: number
  signedUserContentUrls: Record<string, any>
  applicationTransactionNumber: number
  description: string
}

// Now define each of the match types. These are the BASEROW types that are used to match the airtable fields to the baserow fields
// Options are optional as when there is no match, we don't have options
export type MatchingBaserowViewType = {
  id?: number
  name?: string
  slug?: string
  type?: string
  order?: number
  public?: boolean
  table_id?: number
  show_logo?: boolean
  filter_type?: string
  owned_by_id?: number
  ownership_type?: string
  filter_disabled?: boolean
  row_identifier_type?: string
  public_view_has_password?: boolean
  table?: {
    id: number
    name: string
    order: number
    database_id: number
  }
  matchType: BASEROW_IMPORT_MATCH_TYPE
}

// Options are optional as when there is no match, we don't have options
export type MatchingBaserowFieldType = {
  id?: number
  name?: string
  type?: string
  order?: number
  primary?: boolean
  table_id?: number
  read_only?: boolean
  text_default?: string
  matchType: BASEROW_IMPORT_MATCH_TYPE
}

// Options are optional as when there is no match, we don't have options
export type MatchingBaserowTableType = {
  id?: number
  name?: string
  order?: number
  matchType: BASEROW_IMPORT_MATCH_TYPE
  database_id?: number
  // These are the baserow definitions of fields and views. We don't care what they are here as we don't interact with them in the UI
  views?: any
  fields?: any
}

// Now we just enrich the type of the request so there are no discrepancies. Yes, we are getting JSON values from the db, but
// we know what the types are so let's override
export type DbRequestRecord = CellBaserowRequest
export type DetailedRequestRecord = DbRequestRecord & {
  baserowSchema?: any // Again, we don't care about this here
}
export type DetailedRequestRecordWithTables = DbRequestRecord & {
  migrationData: DetailedTableRecord[]
  baserowSchema?: BaserowTableSchema[]
}

// This is the schema from baserow to help match stuff to
export type BaserowTableSchema = {
  id: number
  name: string
  order: number
  database_id: number
  views: MatchingBaserowViewType[]
  fields: MatchingBaserowFieldType[]
}
