import React, { useEffect, useState } from 'react'

import Stack from '@mui/material/Stack'
import {
  DetailedFieldRecord,
  DetailedTableRecord,
  DetailedViewRecord,
  ViewDetails,
} from 'api/src/common/baserowImporterTypes'

import BaserowImporterModalViewDetailsFieldVisibility from 'src/components/BaserowImporter/BaserowImporterModalViewDetailsFieldVisibility'
import BaserowImporterModalViewDetailsFilters from 'src/components/BaserowImporter/BaserowImporterModalViewDetailsFilters'
import BaserowImporterModalViewDetailsFlags from 'src/components/BaserowImporter/BaserowImporterModalViewDetailsFlags'
import BaserowImporterModalViewGrouping from 'src/components/BaserowImporter/BaserowImporterModalViewDetailsGrouping'
import Modal from 'src/components/Modal/Modal'

import BaserowImporterModalViewDetailsDescription from './BaserowImporterModalViewDetailsDescription'
import BaserowImporterModalViewSorting from './BaserowImporterModalViewDetailsSorting'

interface BaserowImporterViewInformationModalProps {
  selectedRecord: DetailedViewRecord
  openModal: boolean
  handleCloseModal: () => void
  tableItem: DetailedTableRecord
}

const BaserowImporterModalViewDetails = ({
  selectedRecord,
  openModal,
  handleCloseModal,
  tableItem,
}: BaserowImporterViewInformationModalProps) => {
  const [enrichedData, setEnrichedData] = useState<DetailedViewRecord>(null)

  useEffect(() => {
    const fieldsWithNames: DetailedFieldRecord[] = tableItem.fields

    // Recursive function to handle all filters including nested ones
    const mapFilters = (filters) => {
      return filters?.map((filter) => {
        if (filter.type === 'nested' && filter.filterSet) {
          return {
            ...filter,
            filterSet: mapFilters(filter.filterSet), // Recursive call to handle deeper nesting
          }
        } else {
          const field: DetailedFieldRecord = fieldsWithNames.find(
            (f) => f.recordId === filter.columnId,
          )
          return {
            ...field,
            ...filter,
          }
        }
      })
    }

    const enrichedView: DetailedViewRecord = {
      ...selectedRecord,
      details: {
        ...(selectedRecord.details as ViewDetails),
        columnOrder: selectedRecord?.details?.columnOrder?.map((col) => {
          const field = fieldsWithNames.find((f) => f.recordId === col.columnId)
          return {
            ...field,
            ...col,
          }
        }),
        filters: {
          ...selectedRecord?.details?.filters,
          filterSet: mapFilters(selectedRecord?.details?.filters?.filterSet),
        },
        colorConfig: {
          ...selectedRecord?.details?.colorConfig,
          colorDefinitions:
            selectedRecord?.details?.colorConfig?.colorDefinitions?.map(
              (colDef) => {
                return {
                  ...colDef,
                  filterSet: mapFilters(colDef.filterSet),
                }
              },
            ),
        },
        groupLevels: selectedRecord?.details?.groupLevels?.map((group) => {
          const field = fieldsWithNames.find(
            (f) => f.recordId === group.columnId,
          )
          return {
            ...field,
            ...group,
          }
        }),
        lastSortsApplied: {
          ...selectedRecord?.details?.lastSortsApplied,
          sortSet: selectedRecord?.details?.lastSortsApplied?.sortSet.map(
            (sort) => {
              const field = fieldsWithNames.find(
                (f) => f.recordId === sort.columnId,
              )
              return {
                ...field,
                ...sort,
              }
            },
          ),
        },
      },
    }

    setEnrichedData(enrichedView)
  }, [selectedRecord, tableItem, openModal]) // ensure fieldsWithNames is stable or appropriately tracked in dependencies

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      title={
        selectedRecord?.name
          ? 'Details for view: ' + selectedRecord?.name
          : 'No view selected'
      }
      footerVisible={true}
      onConfirm={handleCloseModal}
      cancelButtonVisible={false}
      confirmText={'close'}
    >
      {enrichedData && (
        <Stack spacing={2} className={'p-3'}>
          <BaserowImporterModalViewDetailsDescription
            enrichedData={enrichedData}
          />{' '}
          <BaserowImporterModalViewDetailsFieldVisibility
            enrichedData={enrichedData}
          />
          <BaserowImporterModalViewDetailsFilters enrichedData={enrichedData} />
          <BaserowImporterModalViewDetailsFlags enrichedData={enrichedData} />
          <BaserowImporterModalViewGrouping enrichedData={enrichedData} />
          <BaserowImporterModalViewSorting enrichedData={enrichedData} />
        </Stack>
      )}
    </Modal>
  )
}

export default BaserowImporterModalViewDetails
