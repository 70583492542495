import React from 'react'

import { Stack, Typography } from '@mui/material'
import { FilterSetItem } from 'api/src/common/baserowImporterTypes'

import {
  getListOfFieldRequirements,
  splitByCapitals,
} from 'src/components/BaserowImporter/utils'

import BaserowFilterChip from './BaserowFilterChip'

interface BaserowImporterComponentFieldDisplayProps {
  filter: FilterSetItem
}
const BaserowImporterComponentFieldDisplay = ({
  filter,
}: BaserowImporterComponentFieldDisplayProps) => {
  const translateOperator = (operator: string) => {
    switch (operator) {
      case '|':
        return 'Any Of'
      case '=':
        return 'Equals'
      default:
        return operator
    }
  }

  return (
    <Stack direction={'row'} spacing={1}>
      <Typography>Field</Typography>
      <BaserowFilterChip
        className="bg-blue-100 text-blue-500"
        label={filter.name}
      />
      <Typography className={'capitalize'}>
        {splitByCapitals(translateOperator(filter.operator))}
      </Typography>
      {getListOfFieldRequirements(filter)?.map((option) => (
        <BaserowFilterChip
          key={option}
          className="bg-emerald-100 text-emerald-500"
          label={option}
        />
      ))}
    </Stack>
  )
}
export default BaserowImporterComponentFieldDisplay
