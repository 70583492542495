import React from 'react'

import { XMarkIcon } from '@heroicons/react/20/solid'
import { CloudArrowUpIcon, MinusCircleIcon } from '@heroicons/react/24/outline'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import {
  BASEROW_IMPORT_MATCH_TYPE,
  BaserowTableSchema,
  DetailedTableRecord,
} from 'api/src/common/baserowImporterTypes'
import Select from 'react-select'

import Button from 'src/components/Library/Button/Button'

interface BaserowImporterTableComponentProps {
  tableItem: DetailedTableRecord
  deleteTableMatch: (tableId: number) => void
  setTableMatch: (tableId: number, baserowTableId: number) => void
  unmatchedTables: BaserowTableSchema[]
  handleTableCreate: (airtableTableId: number) => void
  handleTableIgnore: (tableId: number) => void
}

const BaserowImporterComponentTableMatch = ({
  tableItem,
  deleteTableMatch,
  setTableMatch,
  unmatchedTables,
  handleTableCreate,
  handleTableIgnore,
}: BaserowImporterTableComponentProps) => {
  const getImportTableStatusLabel = (tableItem: DetailedTableRecord) => {
    if (!tableItem.matchingTable?.matchType) {
      return (
        <Typography className={'text-red-600 capitalize'}>
          {BASEROW_IMPORT_MATCH_TYPE.UNMATCHED}
        </Typography>
      )
    }
    switch (tableItem.matchingTable.matchType) {
      case BASEROW_IMPORT_MATCH_TYPE.DETECTED:
        return (
          <Stack direction={'row'} spacing={1}>
            <Typography className={'text-green-600 capitalize'}>
              {BASEROW_IMPORT_MATCH_TYPE.DETECTED}
            </Typography>
            <Typography>
              {`match with Baserow (${tableItem.matchingTable.name} - ID: ${tableItem.matchingTable.id})`}
            </Typography>
          </Stack>
        )
      case BASEROW_IMPORT_MATCH_TYPE.MANUAL:
        return (
          <Stack direction={'row'} spacing={1}>
            <Typography className={'text-orange-600 capitalize'}>
              {BASEROW_IMPORT_MATCH_TYPE.MANUAL}
            </Typography>
            <Typography>
              {`match with Baserow (${tableItem.matchingTable.name} - ID: ${tableItem.matchingTable.id})`}
            </Typography>
          </Stack>
        )
      case BASEROW_IMPORT_MATCH_TYPE.CREATE:
        return (
          <Typography className={'text-blue-600 capitalize'}>
            {BASEROW_IMPORT_MATCH_TYPE.CREATE}
          </Typography>
        )
      case BASEROW_IMPORT_MATCH_TYPE.IGNORE:
        return (
          <Typography className={'text-gray-600 capitalize'}>
            {`${BASEROW_IMPORT_MATCH_TYPE.IGNORE} table`}
          </Typography>
        )
    }
  }

  return (
    <Box className={'pb-3 border-b-2'}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        className={'flex justify-between'}
        spacing={1}
      >
        <Typography>Table: {tableItem.name}</Typography>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {getImportTableStatusLabel(tableItem)}

          {tableItem.matchingTable?.matchType ? (
            <Tooltip title={'Remove Match'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  className="min-w-0 rounded-full"
                  onClick={() => deleteTableMatch(tableItem.id)}
                >
                  <XMarkIcon className="w-5 h-5 text-red-600" />{' '}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <>
              <Select
                options={unmatchedTables.map((table) => ({
                  value: table.id,
                  label: table.name,
                }))}
                onChange={(selectedOption) => {
                  setTableMatch(tableItem.id, selectedOption.value)
                }}
              />
              <Tooltip title={'Create New Table'}>
                <div>
                  <Button
                    fullWidth={false}
                    variant="text"
                    className="min-w-0 rounded-full"
                    onClick={() => handleTableCreate(tableItem.id)}
                  >
                    <CloudArrowUpIcon className="w-5 h-5 text-blue-600" />{' '}
                  </Button>
                </div>
              </Tooltip>
              <Tooltip title={'Ignore Field'}>
                <div>
                  <Button
                    fullWidth={false}
                    variant="text"
                    className="min-w-0 rounded-full"
                    onClick={() => handleTableIgnore(tableItem.id)}
                  >
                    <MinusCircleIcon className="w-5 h-5 text-red-600" />{' '}
                  </Button>
                </div>
              </Tooltip>
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default BaserowImporterComponentTableMatch
