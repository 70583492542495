import { Stack } from '@mui/material'
import { FilterSetItem } from 'api/src/common/baserowImporterTypes'

import BaserowFilterChip from 'src/components/BaserowImporter/BaserowFilterChip'
import BaserowImporterComponentFieldDisplay from 'src/components/BaserowImporter/BaserowImporterComponentFieldDisplay'

interface BaserowImporterComponentNestedFilterProps {
  filter: FilterSetItem
  level?: number
}

const BaserowImporterComponentNestedFilter = ({
  filter,
  level = 0,
}: BaserowImporterComponentNestedFilterProps) => {
  const indent = '  '.repeat(level * 2)
  return (
    <Stack spacing={1} className={`${indent} border p-2`}>
      <BaserowFilterChip
        className="bg-violet-100 text-violet-500 w-[20%]"
        label={filter.conjunction?.toUpperCase()}
      />
      {filter.filterSet.map((nestedFilter: FilterSetItem, index: number) =>
        nestedFilter.type === 'nested' ? (
          <BaserowImporterComponentNestedFilter
            key={index}
            filter={nestedFilter}
            level={level + 1}
          />
        ) : (
          <BaserowImporterComponentFieldDisplay
            key={nestedFilter.columnId}
            filter={nestedFilter}
          />
        ),
      )}
    </Stack>
  )
}

export default BaserowImporterComponentNestedFilter
