import React, { useState } from 'react'

import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { Chip } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { CellLogType } from 'src/components/BaserowImporter/BaserowImporterLogCell/BaserowImporterLogCell'
import Button from 'src/components/Library/Button/Button'

interface BaserowImporterViewInformationModalProps {
  log: CellLogType
}
const MAXLENGTH = 75

const BaserowImporterModalRequestLog = ({
  log,
}: BaserowImporterViewInformationModalProps) => {
  const [viewFullLog, setViewFullLog] = useState(false)

  const clampText = (text) => {
    if (text.length > MAXLENGTH && !viewFullLog) {
      return text.slice(0, MAXLENGTH) + '...'
    }
    return text
  }

  const getChipColour = (entityType) => {
    switch (entityType) {
      case 'TABLE':
        return 'primary'
      case 'FIELD':
        return 'secondary'
      case 'VIEW':
        return 'success'
      case 'RECORD':
        return 'warning'
      default:
        return 'error'
    }
  }

  return (
    <Stack spacing={2} direction={'row'} alignItems={'center'}>
      {log.logType === 'ERROR' && (
        <ExclamationCircleIcon className={'h-5 w-5 text-red-500'} />
      )}
      {log.logType === 'WARNING' && (
        <ExclamationTriangleIcon className={'h-5 w-5 text-orange-500'} />
      )}
      {log.logType === 'INFO' && (
        <InformationCircleIcon className={'h-5 w-5 text-blue-500'} />
      )}
      <Chip
        label={log.entityType}
        color={getChipColour(log.entityType)}
        size={'small'}
        variant={'outlined'}
      ></Chip>
      <Typography
        variant={'body2'}
        className={`${log.logType === 'ERROR' ? 'text-red-500' : 'text-gray-800'} underline`}
      >{`${log.entityName}`}</Typography>
      <Typography variant={'body2'} className={'text-gray-800'}>
        {'->'}
      </Typography>
      <Typography
        variant={'body2'}
        className={log.logType === 'ERROR' ? 'text-red-600' : 'text-gray-600'}
      >{`${clampText(log.logMessage)}`}</Typography>
      {log.logMessage.length > MAXLENGTH && (
        <Button
          variant={'text'}
          size={'small'}
          onClick={() => setViewFullLog(!viewFullLog)}
          fullWidth={false}
        >
          {viewFullLog ? 'View Less' : 'View More'}
        </Button>
      )}
    </Stack>
  )
}

export default BaserowImporterModalRequestLog
