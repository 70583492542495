import {
  DetailedFieldRecord,
  DetailedTableRecord,
  FilterSetItem,
} from 'api/src/common/baserowImporterTypes'
import dayjs from 'dayjs'

export const SUBMIT_IMPORTER_REQUEST = gql`
  mutation createBaserowImportRequest($input: CreateImportInput!) {
    createBaserowImportRequest(input: $input) {
      id
    }
  }
`
export const UPDATE_IMPORTER_REQUEST = gql`
  mutation updateBaserowImportRequest($input: UpdateImportInput!) {
    updateBaserowImportRequest(input: $input) {
      id
    }
  }
`
export const UPDATE_IMPORTER_FIELD = gql`
  mutation updateAirtableToBaserowMigrationField($input: UpdateFieldInput!) {
    updateAirtableToBaserowMigrationField(input: $input) {
      id
    }
  }
`

export const UPDATE_IMPORTER_FIELDS = gql`
  mutation updateAirtableToBaserowMigrationFields(
    $input: [UpdateFieldInput!]!
  ) {
    updateAirtableToBaserowMigrationFields(input: $input) {
      count
    }
  }
`
export const UPDATE_IMPORTER_VIEW = gql`
  mutation updateAirtableToBaserowMigrationView($input: UpdateViewInput!) {
    updateAirtableToBaserowMigrationView(input: $input) {
      id
    }
  }
`

export const UPDATE_IMPORTER_VIEWS = gql`
  mutation updateAirtableToBaserowMigrationViews($input: [UpdateViewInput!]!) {
    updateAirtableToBaserowMigrationViews(input: $input) {
      count
    }
  }
`
export const UPDATE_IMPORTER_TABLE = gql`
  mutation updateAirtableToBaserowMigrationTable($input: UpdateTableInput!) {
    updateAirtableToBaserowMigrationTable(input: $input) {
      id
    }
  }
`
export const RESET_IMPORTER_REQUEST = gql`
  mutation resetBaserowImportRequest($input: ResetImportInput!) {
    resetBaserowImportRequest(input: $input) {
      id
    }
  }
`
export const PROCESS_IMPORTER_REQUEST = gql`
  mutation processBaserowImportRequest($input: ProcessImportInput!) {
    processBaserowImportRequest(input: $input) {
      id
    }
  }
`

export const splitByCapitals = (inputString: string) => {
  // Add a space before each capital letter, except the first character if it is capital
  let formattedString = inputString.replace(/([A-Z])/g, ' $1').trim()

  // Capitalize the first letter if it's not already
  formattedString =
    formattedString.charAt(0).toUpperCase() + formattedString.slice(1)

  return formattedString
}
export const getColourFromAirtableColour = (colour: string) => {
  switch (colour) {
    case 'pinkDarker':
      return {
        backgroundColor: '#E929BA',
        color: 'white',
      }
    case 'pinkDark':
      return {
        backgroundColor: '#B2158B',
        color: 'white',
      }
    case 'pinkMedium':
      return {
        backgroundColor: '#F99DE2',
        color: 'black',
      }
    case 'pink':
      return {
        backgroundColor: '#FFDAF6',
        color: 'black',
      }

    case 'purpleDarker':
      return {
        backgroundColor: '#7C39ED',
        color: 'white',
      }
    case 'purpleDark':
      return {
        backgroundColor: '#6B1CB0',
        color: 'white',
      }
    case 'purpleMedium':
      return {
        backgroundColor: '#CDB0FF',
        color: 'black',
      }
    case 'purple':
      return {
        backgroundColor: '#EDE2FE',
        color: 'black',
      }

    case 'blueDarker':
      return {
        backgroundColor: '#1283DA',
        color: 'white',
      }
    case 'blueDark':
      return {
        backgroundColor: '#2750AE',
        color: 'white',
      }
    case 'blueMedium':
      return {
        backgroundColor: '#9CC7FF',
        color: 'black',
      }
    case 'blue':
      return {
        backgroundColor: '#CFDFFF',
        color: 'black',
      }

    case 'cyanDarker':
      return {
        backgroundColor: '#01A9DB',
        color: 'white',
      }
    case 'cyanDark':
      return {
        backgroundColor: '#0B76B7',
        color: 'white',
      }
    case 'cyanMedium':
      return {
        backgroundColor: '#77D1F3',
        color: 'black',
      }
    case 'cyan':
      return {
        backgroundColor: '#D0F0FD',
        color: 'black',
      }

    case 'tealDarker':
      return {
        backgroundColor: '#02AAA4',
        color: 'white',
      }
    case 'tealDark':
      return {
        backgroundColor: '#06A09B',
        color: 'white',
      }
    case 'tealMedium':
      return {
        backgroundColor: '#72DDC3',
        color: 'black',
      }
    case 'teal':
      return {
        backgroundColor: '#C2F5E9',
        color: 'black',
      }

    case 'greenDarker':
      return {
        backgroundColor: '#11AF22',
        color: 'white',
      }
    case 'greenDark':
      return {
        backgroundColor: '#338A17',
        color: 'white',
      }
    case 'greenMedium':
      return {
        backgroundColor: '#93E088',
        color: 'black',
      }
    case 'green':
      return {
        backgroundColor: '#D1F7C4',
        color: 'black',
      }

    case 'grayDarker':
      return {
        backgroundColor: '#666666',
        color: 'white',
      }
    case 'grayDark':
      return {
        backgroundColor: '#444444',
        color: 'white',
      }
    case 'grayMedium':
      return {
        backgroundColor: '#CCCCCC',
        color: 'black',
      }
    case 'gray':
      return {
        backgroundColor: '#EEEEEE',
        color: 'black',
      }

    case 'yellowDarker':
      return {
        backgroundColor: '#E08D00',
        color: 'white',
      }
    case 'yellowDark':
      return {
        backgroundColor: '#B87503',
        color: 'white',
      }
    case 'yellowMedium':
      return {
        backgroundColor: '#FFD66E',
        color: 'black',
      }
    case 'yellow':
      return {
        backgroundColor: '#FFEA86',
        color: 'black',
      }

    case 'orangeDarker':
      return {
        backgroundColor: '#F7653B',
        color: 'white',
      }
    case 'orangeDark':
      return {
        backgroundColor: '#D74D26',
        color: 'white',
      }
    case 'orangeMedium':
      return {
        backgroundColor: '#FFA981',
        color: 'black',
      }
    case 'orange':
      return {
        backgroundColor: '#FEE2D5',
        color: 'black',
      }

    case 'redDarker':
      return {
        backgroundColor: '#EF3061',
        color: 'white',
      }
    case 'redDark':
      return {
        backgroundColor: '#BA1E45',
        color: 'white',
      }
    case 'redMedium':
      return {
        backgroundColor: '#FF9EB7',
        color: 'black',
      }
    case 'red':
      return {
        backgroundColor: '#FFDCE5',
        color: 'black',
      }

    default:
      return {
        backgroundColor: '#CCCCCC',
      }
  }
}

export const getListOfFieldRequirements = (filter: FilterSetItem) => {
  const filterType = filter.type
  const operator = filter.operator
  switch (operator) {
    case '|':
    case 'isAnyOf':
      return filter?.value?.map((option) => {
        const matchingOption = filter.options?.choices?.find(
          (choice) => choice.id === option,
        )
        return matchingOption?.name || option
      })
    case 'contains':
      return [filter.value]
    case 'filename':
      return [filter.value]
    case '=':
      if (typeof filter.value === 'object') {
        if (Array.isArray(filter.value)) {
          return filter.value.map((option) => {
            if (typeof option === 'string') {
              const matchingOption = filter.options?.choices?.find(
                (choice) => choice.id === option,
              )
              return matchingOption?.name || option
            }
            return option
          })
        }

        switch (filter.value.mode) {
          case 'daysAgo':
            return [`${filter.value.numberOfDays} Day Ago`]
          case 'daysFromNow':
            return [`${filter.value.numberOfDays} Day From Now`]
          case 'exactDate':
            return [dayjs(filter.value.exactDate).format('DD/MM/YYYY')]
          case 'oneWeekFromNow':
            return ['One Week From Now']
          case 'oneWeekAgo':
            return ['One Week Ago']
          default:
            return ['Invalid date']
        }
      } else if (typeof filter.value === 'string') {
        return [filter.value]
      } else if (typeof filter.value === 'number') {
        return [filter.value.toString()]
      } else if (typeof filter.value === 'boolean') {
        return [filter.value.toString()]
      }
      break
    case '>=':
      if (filterType === 'lastModifiedTime') {
        return [dayjs(filter.value.exactDate).format('DD/MM/YYYY')]
      }
  }
}

const stringifyFilters = (
  filterSet: FilterSetItem[],
  conjunction: string,
  tableData: DetailedTableRecord[],
  level: number = 0,
) => {
  // Helper function to find field name by ID across all tables
  const getFieldNameById = (id: string) => {
    // Find across all fields in all tables
    for (const table of tableData) {
      const field = table.fields.find((field) => field.recordId === id)
      if (field) {
        return field.name
      }
    }
    return id // Return the ID if no field is found
  }

  const resultString = filterSet
    .map((filter) => {
      const indent = '  '.repeat(level)
      if (filter.type === 'nested' && filter.filterSet) {
        // Recursive call with nested filter set and its conjunction
        const nestedFilters = stringifyFilters(
          filter.filterSet,
          filter.conjunction,
          tableData,
          level + 1,
        )
        return `${indent}(${nestedFilters})`
      } else {
        // Handle normal filters
        const value = filter.value === null ? 'NULL' : `"${filter.value}"`
        const fieldName = getFieldNameById(filter.columnId)
        return `${indent}${fieldName} ${filter.operator.toUpperCase()} ${value}`
      }
    })
    .join(` ${conjunction.toUpperCase()} `)

  // Add outermost parentheses for top-level group for clarity if nested
  return level === 0 ? resultString : `(${resultString})`
}

export type FieldOptionForChipType = {
  title: string
  label: string
  color?: string
}

export const returnFieldOptions = (
  field: DetailedFieldRecord,
  tableItem: DetailedTableRecord,
  tableData: DetailedTableRecord[],
) => {
  switch (field.type) {
    // AI Text -> Not supported because I'm not paying for it.
    // Sync source -> Unknown -> Can't Replicate
    case 'multipleAttachments':
      return [
        {
          ...(field.details?.typeOptions &&
          'unreversed' in field.details.typeOptions
            ? {
                title: 'Disable Colours',
                label: field.details.typeOptions.unreversed?.toString(),
              }
            : {}),
        },
      ]
    case 'multipleSelects':
    case 'singleSelect':
      return [
        ...field.details.typeOptions.choiceOrder.map((id, index) => {
          const choice = field.details.typeOptions.choices[id]
          return {
            title: index.toString(),
            label: choice.name,
            color: getColourFromAirtableColour(choice.color),
          }
        }),
        {
          ...(field.details?.typeOptions &&
          'disableColors' in field.details.typeOptions
            ? {
                title: 'Disable Colours',
                label: field.details.typeOptions.disableColors?.toString(),
              }
            : {}),
        },
      ]
    case 'lastModifiedTime':
    case 'createdTime':
    case 'date':
    case 'dateTime':
      return [
        {
          ...(field.details?.typeOptions &&
          'dateFormat' in field.details.typeOptions
            ? {
                title: 'Date Format',
                label: field.details.typeOptions.dateFormat,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'isDateTime' in field.details.typeOptions
            ? {
                title: 'Show Time',
                label: field.details.typeOptions.isDateTime?.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'timeFormat' in field.details.typeOptions
            ? {
                title: 'Time Format',
                label: field.details.typeOptions.timeFormat,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'shouldDisplayTimeZone' in field.details.typeOptions
            ? {
                title: 'Display Time Zone',
                label:
                  field.details.typeOptions.shouldDisplayTimeZone.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'timeZone' in field.details.typeOptions
            ? {
                title: 'Timezone',
                label: field.details.typeOptions.timeZone,
              }
            : {}),
        },
      ]
    case 'multiCollaborator':
    case 'singleCollaborator':
      return [
        {
          ...(field.details?.typeOptions &&
          'canAddNonBaseCollaborators' in field.details.typeOptions
            ? {
                title: 'Can Add Non Members',
                label:
                  field.details.typeOptions.canAddNonBaseCollaborators.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'shouldNotify' in field.details.typeOptions
            ? {
                title: 'Notify User',
                label: field.details.typeOptions.shouldNotify?.toString(),
              }
            : {}),
        },
      ]
    case 'multipleLookupValues':
      return [
        {
          ...(field.details?.typeOptions &&
          'relationColumnId' in field.details.typeOptions
            ? {
                title: 'Linked To Field',
                label: tableItem.fields.find(
                  (fieldItem) =>
                    fieldItem.recordId ===
                    field.details.typeOptions?.relationColumnId,
                )?.name,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultType' in field.details.typeOptions
            ? {
                title: 'Result Type',
                label: field.details.typeOptions.resultType,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultIsArray' in field.details.typeOptions
            ? {
                title: 'Is Array',
                label: field.details.typeOptions.resultIsArray?.toString(),
              }
            : {}),
        },

        {
          ...(field.details?.typeOptions &&
          'rowLimit' in field.details.typeOptions
            ? {
                title: 'Row Limit',
                label: `${field.details.typeOptions.rowLimit?.firstOrLast} ${field.details.typeOptions.rowLimit?.limit}`,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'filters' in field.details.typeOptions
            ? {
                title: 'Filters',
                label: stringifyFilters(
                  field.details.typeOptions.filters.filterSet,
                  field.details.typeOptions.filters.conjunction,
                  tableData,
                ),
              }
            : {}),
        },
      ]
    case 'multipleRecordLinks':
      return [
        {
          ...(field.details?.typeOptions &&
          'foreignTableId' in field.details.typeOptions
            ? {
                title: 'Linked Table',
                label: tableData.find(
                  (table) =>
                    table.recordId ===
                    field?.details?.typeOptions?.foreignTableId,
                )?.name,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'relationship' in field.details.typeOptions
            ? {
                title: 'Relationship Type',
                label: field?.details?.typeOptions?.relationship,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'foreignTableId' in field.details.typeOptions
            ? {
                title: 'Symmetry Field',
                label: tableData
                  .find(
                    (table) =>
                      table.recordId ===
                      field?.details?.typeOptions?.foreignTableId,
                  )
                  ?.fields?.find(
                    (fieldItem) =>
                      fieldItem.recordId ===
                      field?.details?.typeOptions?.symmetricColumnId,
                  )?.name,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'unreversed' in field.details.typeOptions
            ? {
                title: 'Un-Reversed',
                label:
                  field.details.typeOptions.unreversed?.toString() ?? 'N/A',
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'filtersForRecordSelection' in field.details.typeOptions
            ? {
                title: 'Filters',
                label: stringifyFilters(
                  field.details.typeOptions.filtersForRecordSelection.filterSet,
                  field.details.typeOptions.filtersForRecordSelection
                    .conjunction,
                  tableData,
                ),
              }
            : {}),
        },
      ]
    case 'formula':
      return [
        {
          ...(field.details?.typeOptions &&
          'formulaTextParsed' in field.details.typeOptions
            ? {
                title: 'Formula',
                label: field.details.typeOptions.formulaTextParsed,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultType' in field.details.typeOptions
            ? {
                title: 'Result Type',
                label: field.details.typeOptions.resultType,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultIsArray' in field.details.typeOptions
            ? {
                title: 'Result Is Array',
                label: field.details.typeOptions.resultIsArray.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions?.dependencies &&
          'referencedColumnIdsForValue' in
            field.details.typeOptions.dependencies
            ? {
                title: 'Relies On Fields',
                label:
                  field.details.typeOptions.dependencies.referencedColumnIdsForValue.map(
                    (id) =>
                      tableItem.fields.find((field) => field.recordId === id)
                        ?.name,
                  ),
              }
            : {}),
        },
      ]
    case 'rollup':
      return [
        {
          ...(field.details?.typeOptions &&
          'formulaTextParsed' in field.details.typeOptions
            ? {
                title: 'Formula',
                label: field.details.typeOptions.formulaTextParsed,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultType' in field.details.typeOptions
            ? {
                title: 'Result Type',
                label: field.details.typeOptions.resultType,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultIsArray' in field.details.typeOptions
            ? {
                title: 'Is Array',
                label: field.details.typeOptions.resultIsArray?.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'relationColumnId' in field.details.typeOptions
            ? {
                title: 'Relies On Fields',
                label: tableData
                  .flatMap((table) => table.fields)
                  ?.find(
                    (fieldItem) =>
                      fieldItem.recordId ===
                      field?.details?.typeOptions?.foreignTableRollupColumnId,
                  )?.name,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'filters' in field.details.typeOptions
            ? {
                title: 'Filters',
                label: stringifyFilters(
                  field.details.typeOptions.filters.filterSet,
                  field.details.typeOptions.filters.conjunction,
                  tableData,
                ),
              }
            : {}),
        },
      ]
    case 'count':
      return [
        {
          ...(field.details?.typeOptions &&
          'resultType' in field.details.typeOptions
            ? {
                title: 'Result Type',
                label: field.details.typeOptions.resultType,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultIsArray' in field.details.typeOptions
            ? {
                title: 'Is Array',
                label: field.details.typeOptions.resultIsArray?.toString(),
              }
            : {}),
        },

        {
          ...(field.details?.typeOptions?.dependencies &&
          'referencedColumnIdsForValue' in
            field.details.typeOptions.dependencies
            ? {
                title: 'Relies On Fields',
                label:
                  field.details.typeOptions.dependencies.referencedColumnIdsForValue.map(
                    (id) =>
                      tableItem.fields.find((field) => field.recordId === id)
                        ?.name,
                  ),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'filters' in field.details.typeOptions
            ? {
                title: 'Filters',
                label: stringifyFilters(
                  field.details.typeOptions.filters.filterSet,
                  field.details.typeOptions.filters.conjunction,
                  tableData,
                ),
              }
            : {}),
        },
      ]
    case 'duration':
    case 'percent':
    case 'number':
    case 'currency':
      return [
        {
          ...(field.details?.typeOptions &&
          'durationFormat' in field.details.typeOptions
            ? {
                title: 'Duration Format',
                label: field.details?.typeOptions?.durationFormat,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'format' in field.details.typeOptions
            ? {
                title: 'Format',
                label: field.details.typeOptions.format,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'negative' in field.details.typeOptions
            ? {
                title: 'Allow Negative',
                label: field.details.typeOptions.negative?.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'precision' in field.details.typeOptions
            ? {
                title: 'Precision',
                label: field.details.typeOptions.precision?.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'symbol' in field.details.typeOptions
            ? {
                title: 'Symbol',
                label: field.details.typeOptions.symbol,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'validatorName' in field.details.typeOptions
            ? {
                title: 'Validator Name',
                label: field.details.typeOptions.validatorName,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'separatorFormat' in field.details.typeOptions
            ? {
                title: 'Separator Format',
                label: field.details.typeOptions.separatorFormat,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'shouldShowThousandsSeparator' in field.details.typeOptions
            ? {
                title: 'Thousand Separator',
                label:
                  field.details.typeOptions.shouldShowThousandsSeparator.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'abbreviation' in field.details.typeOptions
            ? {
                title: 'Abbreviation',
                label: field.details.typeOptions.abbreviation.toString(),
              }
            : {}),
        },
      ]
    case 'checkbox':
      return [
        {
          ...(field.details?.typeOptions && 'color' in field.details.typeOptions
            ? {
                title: 'Colour',
                label: field.details.typeOptions.color,
                color: field.details.typeOptions.color,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions && 'icon' in field.details.typeOptions
            ? {
                title: 'Icon',
                label: field.details.typeOptions.icon,
              }
            : {}),
        },
      ]
    case 'rating':
      return [
        {
          ...(field.details?.typeOptions && 'color' in field.details.typeOptions
            ? {
                title: 'Colour',
                label: field.details.typeOptions.color,
                color: field.details.typeOptions.color,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions && 'icon' in field.details.typeOptions
            ? {
                title: 'Icon',
                label: field.details.typeOptions.icon,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions && 'max' in field.details.typeOptions
            ? {
                title: 'Max',
                label: field.details.typeOptions.max.toString(),
              }
            : {}),
        },
      ]
    case 'button':
      return [
        {
          ...(field.details?.typeOptions &&
          'actionType' in field.details.typeOptions
            ? {
                title: 'Action Type',
                label: field.details.typeOptions.actionType,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'formulaTextParsed' in field.details.typeOptions
            ? {
                title: 'Formula',
                label: field.details.typeOptions.formulaTextParsed,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions && 'label' in field.details.typeOptions
            ? {
                title: 'Label',
                label: `${field.details.typeOptions.label.type} - ${field.details.typeOptions.label.staticText}`,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'variant' in field.details.typeOptions
            ? {
                title: 'Variant',
                label: `${field.details.typeOptions.variant.type} - ${field.details.typeOptions.variant.staticVariant}`,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultIsArray' in field.details.typeOptions
            ? {
                title: 'Is Array',
                label: field.details.typeOptions.resultIsArray?.toString(),
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions &&
          'resultType' in field.details.typeOptions
            ? {
                title: 'Result Type',
                label: field.details.typeOptions.resultType,
              }
            : {}),
        },
        {
          ...(field.details?.typeOptions?.dependencies &&
          'referencedColumnIdsForValue' in
            field.details.typeOptions.dependencies
            ? {
                title: 'Relies On Fields',
                label:
                  field.details.typeOptions.dependencies.referencedColumnIdsForValue.map(
                    (id) =>
                      tableItem.fields.find((field) => field.recordId === id)
                        ?.name,
                  ),
              }
            : {}),
        },
      ]
    case 'singleLineText':
    case 'multilineText':
    case 'autoNumber':
    case 'barcode':
    case 'createdBy':
    case 'lastModifiedBy':
    case 'email':
    case 'phoneNumber':
    case 'richText':
    case 'url':
    default:
      return [
        {
          label: 'None',
        },
      ]
  }
}

// Airtable:Baserow field types. Comment purely for checking purposes. Please do not deletearoo

// aiText : ai
// autoNumber : autonumber
// barcode : NOT AVAILABLE -> Text
// button : NOT AVAILABLE -> Text
// checkbox : boolean
// createdBy: created_by
// createdTime : created_on
// currency : NOT AVAILABLE -> Number
// date : date
// dateTime : date
// duration : duration
// email : email
// externalSyncSource : NOT AVAILABLE -> Text
// lastModifiedBy : last_modified_by
// lastModifiedTime : last_modified
// multilineText : long_text
// multipleAttachments : file
// multipleCollaborators : multiple_collaborators
// multipleSelects : multiple_select
// number : number
// NOT AVAILABLE - password
// percent : NOT AVAILABLE -> Number
// phoneNumber : phone_number
// rating : rating
// richText : long_text
// singleCollaborator : multiple_collaborators
// singleLineText : text
// singleSelect : single_select
// url : url
// NOT AVAILABLE - uuid

// count : count
// formula : formula
// multipleRecordLinks : link_row
// multipleLookupValues : lookup
// rollup : rollup
