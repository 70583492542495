import React, { Dispatch, SetStateAction, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Box, Chip } from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DetailedRequestRecord } from 'api/src/common/baserowImporterTypes'
import {
  AirtableToBaserowImporterStatus,
  processBaserowImportRequest,
  processBaserowImportRequestVariables,
  resetBaserowImportRequest,
  resetBaserowImportRequestVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { setStringToTitleCase } from 'src/components/Automations/utils'
import { RefetchQuery } from 'src/components/BaserowImporter/BaserowImporterCell/BaserowImporterCell'
import BaserowImporterModalCreateRequest from 'src/components/BaserowImporter/BaserowImporterModalCreateRequest'
import {
  PROCESS_IMPORTER_REQUEST,
  RESET_IMPORTER_REQUEST,
} from 'src/components/BaserowImporter/utils'
import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import BaserowImporterLogCell from './BaserowImporterLogCell'

interface BaserowImporterMatchSectionProps {
  importRequests: DetailedRequestRecord[]
  refetchQuery: RefetchQuery
  selectedRequest: DetailedRequestRecord
  setSelectedRequest: Dispatch<SetStateAction<DetailedRequestRecord>>
}
const BaserowImporterHeaderSection = ({
  importRequests,
  refetchQuery,
  selectedRequest,
  setSelectedRequest,
}: BaserowImporterMatchSectionProps) => {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [openLogModal, setOpenLogModal] = useState(false)

  const requestStatusChip = (status: AirtableToBaserowImporterStatus) => {
    switch (status) {
      case 'PENDING':
      case 'PENDING_PROCESSING':
        return (
          <Chip
            size={'small'}
            label={setStringToTitleCase(status)}
            variant={'outlined'}
            className={
              'rounded text-sm bg-orange-100 text-orange-500 px-2 capitalize'
            }
          />
        )
      case 'EXTRACTING_AIRTABLE':
      case 'EXTRACTING_BASEROW':
      case 'MATCHING_SCHEMAS':
        return (
          <Chip
            size={'small'}
            label={setStringToTitleCase(status)}
            variant={'outlined'}
            className={
              'rounded text-sm bg-cyan-100 text-cyan-500 px-2 capitalize'
            }
          />
        )
      case 'SCHEMA_READY':
        return (
          <Chip
            size={'small'}
            label={setStringToTitleCase(status)}
            variant={'outlined'}
            className={
              'rounded text-sm bg-lime-100 text-lime-500 px-2 capitalize'
            }
          />
        )
      case 'PROCESSING_TABLES':
      case 'PROCESSING_FIELDS':
      case 'PROCESSING_DEPENDENCIES':
      case 'PROCESSING_VIEWS':
      case 'PROCESSING_DATA':
      case 'PROCESSING_COMMENTS':
        return (
          <Chip
            size={'small'}
            label={setStringToTitleCase(status)}
            variant={'outlined'}
            className={
              'rounded text-sm bg-violet-100 text-violet-500 px-2 capitalize'
            }
          />
        )
      case 'FAILED':
        return (
          <Chip
            size={'small'}
            label={setStringToTitleCase(status)}
            variant={'outlined'}
            className={
              'rounded text-sm bg-red-100 text-red-500 px-2 capitalize'
            }
          />
        )
      case 'MIGRATION_COMPLETE':
        return (
          <Chip
            size={'small'}
            label={setStringToTitleCase(status)}
            variant={'outlined'}
            className={
              'rounded text-sm bg-green-100 text-green-500 px-2 capitalize'
            }
          />
        )
    }
  }

  const processConfirm = useConfirm()
  const resetConfirm = useConfirm()

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false)
  }

  const refreshData = async () => {
    setRefetching(true)

    await refetchQuery()
    setSelectedRequest(importRequests[0])
    setRefetching(false)
  }

  const handleResetJob = async (requestId: number) => {
    await resetJobRequest({
      variables: {
        input: {
          id: requestId,
        },
      },
    })
  }

  const [resetJobRequest] = useMutation<
    resetBaserowImportRequest,
    resetBaserowImportRequestVariables
  >(RESET_IMPORTER_REQUEST, {
    onCompleted: (_data) => {
      toast.success('Job reset successfully')
    },
    refetchQueries: [
      'FindAirtableToBaserowImporterData',
      'FindAirtableToBaserowRequest',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const handleStartProcessing = async (requestId: number) => {
    await processJobRequest({
      variables: {
        input: {
          id: requestId,
        },
      },
    })
  }

  const [processJobRequest] = useMutation<
    processBaserowImportRequest,
    processBaserowImportRequestVariables
  >(PROCESS_IMPORTER_REQUEST, {
    onCompleted: (_data) => {
      toast.success('Job processed successfully')
    },
    refetchQueries: [
      'FindAirtableToBaserowImporterData',
      'FindAirtableToBaserowRequest',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  return (
    <>
      <Box className={'bg-white border-l-2 border-r-2 border-t-2 p-3'}>
        <Stack
          direction={'row'}
          spacing={2}
          className={'w-full flex justify-between'}
          alignItems={'center'}
        >
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            className={'w-[50%]'}
          >
            <AutoComplete
              options={importRequests}
              size="small"
              renderInput={(params) => (
                <TextField size={'small'} {...params} label="Request" />
              )}
              onChange={(_, newValue) => {
                setSelectedRequest(newValue)
              }}
              value={selectedRequest}
              getOptionLabel={(option) => option.title}
              className={'min-w-[50%]'}
            />
            <Button
              onClick={() => setOpenLogModal(true)}
              disabled={refetching || !selectedRequest}
              className="mb-5"
              variant="outlined"
              size="small"
              fullWidth={false}
              loading={refetching}
            >
              View Log
            </Button>
            {requestStatusChip(selectedRequest?.status)}
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <Button
              onClick={refreshData}
              className="mb-5"
              variant="outlined"
              size="small"
              fullWidth={false}
              loading={refetching}
            >
              Refresh
            </Button>
            <Button
              onClick={() => setOpenCreateModal(true)}
              className="mb-5"
              variant="contained"
              size="small"
              fullWidth={false}
            >
              Create New Import Request
            </Button>
            <Button
              onClick={() => {
                processConfirm({
                  title: 'Process Import',
                  description: 'Are you sure you want to process this import?',
                })
                  .then(() => {
                    handleStartProcessing(selectedRequest.id)
                  })
                  .catch(() => {})
              }}
              className="mb-5 bg-green-500 "
              variant="contained"
              size="small"
              fullWidth={false}
              disabled={
                ![
                  'SCHEMA_READY',
                  'PENDING_PROCESSING',
                  'PROCESSING_SCHEMAS',
                  'PROCESSING_FIELDS',
                  'PROCESSING_DEPENDENCIES',
                  'PROCESSING_VIEWS',
                  'PROCESSING_DATA',
                  'PROCESSING_COMMENTS',
                  'MIGRATION_COMPLETE',
                ].includes(selectedRequest?.status)
              }
            >
              Process
            </Button>
            <Button
              onClick={() => {
                resetConfirm({
                  title: 'Reset Import',
                  description: 'Are you sure you want to reset this import?',
                })
                  .then(() => {
                    handleResetJob(selectedRequest.id)
                  })
                  .catch(() => {})
              }}
              className="mb-5 bg-red-500"
              variant="contained"
              size="small"
              fullWidth={false}
              disabled={!selectedRequest}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
      </Box>
      <BaserowImporterModalCreateRequest
        openModal={openCreateModal}
        handleCloseModal={handleCloseCreateModal}
      />
      {selectedRequest && openLogModal && (
        <BaserowImporterLogCell
          openModal={openLogModal}
          handleCloseModal={() => setOpenLogModal(false)}
          requestId={selectedRequest.id}
        />
      )}
    </>
  )
}

export default BaserowImporterHeaderSection
