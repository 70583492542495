import React, { Dispatch, SetStateAction, useState } from 'react'

import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { Box } from '@mui/material'
import {
  BASEROW_IMPORT_MATCH_TYPE,
  DetailedFieldRecord,
  DetailedTableRecord,
  DetailedViewRecord,
  MatchingBaserowFieldType,
  MatchingBaserowViewType,
} from 'api/src/common/baserowImporterTypes'
import {
  updateAirtableToBaserowMigrationField,
  updateAirtableToBaserowMigrationFields,
  updateAirtableToBaserowMigrationFieldsVariables,
  updateAirtableToBaserowMigrationFieldVariables,
  updateAirtableToBaserowMigrationView,
  updateAirtableToBaserowMigrationViews,
  updateAirtableToBaserowMigrationViewsVariables,
  updateAirtableToBaserowMigrationViewVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { QUERY as FindAirtableToBaserowImporterData } from 'src/components/BaserowImporter/BaserowImporterCell'
import BaserowImporterComponentFieldList from 'src/components/BaserowImporter/BaserowImporterComponentFieldList'
import BaserowImporterComponentViewList from 'src/components/BaserowImporter/BaserowImporterComponentViewList'
import { QUERY as FindAirtableToBaserowRequest } from 'src/components/BaserowImporter/BaserowImporterRecordCell'
import { CellMigrationType } from 'src/components/BaserowImporter/BaserowImporterRecordCell/BaserowImporterRecordCell'
import {
  UPDATE_IMPORTER_FIELD,
  UPDATE_IMPORTER_FIELDS,
  UPDATE_IMPORTER_VIEW,
  UPDATE_IMPORTER_VIEWS,
} from 'src/components/BaserowImporter/utils'
import { default as EmptyData } from 'src/components/Library/Empty'
import Tab from 'src/components/Library/Tab'
import Tabs from 'src/components/Library/Tabs'

interface BaserowImporterMainSectionProps {
  sortedDetailedTableData: DetailedTableRecord[]
  setDetailedTableData: Dispatch<SetStateAction<DetailedTableRecord[]>>
  selectedTableIndex: number
  table: DetailedTableRecord
  migrationRequest: CellMigrationType
}

const BaserowImporterSectionRight = ({
  sortedDetailedTableData,
  selectedTableIndex,
  setDetailedTableData,
  table,
  migrationRequest,
}: BaserowImporterMainSectionProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const handleTabChange = (_event, newValue: number) => {
    setSelectedTabIndex(newValue)
  }

  const handleFieldMatch = async (
    airtableFieldId: number,
    baserowFieldId: number,
  ) => {
    let matchData: MatchingBaserowFieldType = null
    const airtTable: DetailedTableRecord =
      sortedDetailedTableData[selectedTableIndex]
    const baserowField = airtTable.matchingTable.fields.find(
      (field) => field.id === baserowFieldId,
    )
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table: DetailedTableRecord) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedFields: DetailedFieldRecord[] = table.fields.map(
            (field: DetailedFieldRecord) => {
              if (field.id === airtableFieldId) {
                matchData = {
                  ...baserowField,
                  matchType: BASEROW_IMPORT_MATCH_TYPE.MANUAL,
                }
                return {
                  ...field,
                  matchingField: matchData,
                }
              }
              return field
            },
          )
          return {
            ...table,
            fields: updatedFields,
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await updateFieldRecord({
      variables: {
        input: {
          id: airtableFieldId,
          matchingField: matchData,
        },
      },
    })
  }

  const handleFieldCreate = async (airtableFieldId: number) => {
    const matchData: MatchingBaserowFieldType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.CREATE,
    }

    const input = updateLocalStateAndGenerateInput(
      [airtableFieldId],
      matchData,
    )[0]

    await updateFieldRecord({
      variables: {
        input,
      },
    })
  }

  const handleFieldCreateAll = async (airtableFieldIds: number[]) => {
    const matchData: MatchingBaserowFieldType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.CREATE,
    }

    const inputs = updateLocalStateAndGenerateInput(airtableFieldIds, matchData)

    await updateFieldRecords({
      variables: {
        input: inputs,
      },
    })
  }

  const handleFieldIgnore = async (airtableFieldId: number) => {
    const matchData: MatchingBaserowFieldType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.IGNORE,
    }
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedFields: DetailedFieldRecord[] = table.fields.map(
            (field: DetailedFieldRecord) => {
              if (field.id === airtableFieldId) {
                return {
                  ...field,
                  matchingField: matchData,
                }
              }
              return field
            },
          )

          return {
            ...table,
            fields: updatedFields,
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await updateFieldRecord({
      variables: {
        input: {
          id: airtableFieldId,
          matchingField: matchData,
        },
      },
    })
  }

  const deleteFieldMatch = async (airtableFieldId: number) => {
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedFields: DetailedFieldRecord[] = table.fields.map(
            (field: DetailedFieldRecord) => {
              if (field.id === airtableFieldId) {
                const { matchingField, ...restOfField } = field
                return { ...restOfField }
              }
              return field
            },
          )
          return {
            ...table,
            fields: updatedFields,
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await updateFieldRecord({
      variables: {
        input: {
          id: airtableFieldId,
          matchingField: null,
        },
      },
    })
  }

  const updateLocalStateAndGenerateInput = (
    airtableFieldIds: number[],
    matchData: MatchingBaserowFieldType,
  ) => {
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedFields: DetailedFieldRecord[] = table.fields.map(
            (field: DetailedFieldRecord) => {
              if (airtableFieldIds.includes(field.id)) {
                return {
                  ...field,
                  matchingField: matchData,
                }
              }
              return field
            },
          )

          return {
            ...table,
            fields: updatedFields,
          }
        }
        return table
      },
    )

    setDetailedTableData(newData)

    return airtableFieldIds.map((id) => ({
      id,
      matchingField: matchData,
    }))
  }

  const handleViewCreate = async (airtableViewId: number) => {
    const matchData: MatchingBaserowViewType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.CREATE,
    }

    const input = updateLocalStateAndGenerateInputForViews(
      [airtableViewId],
      matchData,
    )[0]

    await updateViewRecord({
      variables: {
        input,
      },
    })
  }

  const handleViewCreateAll = async (airtableViewIds: number[]) => {
    const matchData: MatchingBaserowViewType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.CREATE,
    }

    const inputs = updateLocalStateAndGenerateInputForViews(
      airtableViewIds,
      matchData,
    )

    await updateViewRecords({
      variables: {
        input: inputs,
      },
    })
  }

  const handleViewIgnore = async (airtableViewId: number) => {
    const matchData: MatchingBaserowViewType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.IGNORE,
    }
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedViews: DetailedViewRecord[] = table.views.map(
            (view: DetailedViewRecord) => {
              if (view.id === airtableViewId) {
                return {
                  ...view,
                  matchingView: matchData,
                }
              }
              return view
            },
          )

          return {
            ...table,
            views: updatedViews,
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await updateViewRecord({
      variables: {
        input: {
          id: airtableViewId,
          matchingView: matchData,
        },
      },
    })
  }

  const handleViewMatch = async (
    airtableViewId: number,
    baserowViewId: number,
  ) => {
    let matchData: MatchingBaserowViewType = null
    // Find Baserow view from the ID
    const airtTable: DetailedTableRecord =
      sortedDetailedTableData[selectedTableIndex]
    const baserowView = airtTable.matchingTable.views.find(
      (view) => view.id === baserowViewId,
    )
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedViews: DetailedViewRecord[] = table.views.map(
            (view: DetailedViewRecord) => {
              if (view.id === airtableViewId) {
                matchData = {
                  ...baserowView,
                  matchType: BASEROW_IMPORT_MATCH_TYPE.MANUAL,
                }
                return {
                  ...view,
                  matchingView: matchData,
                }
              }
              return view
            },
          )

          return {
            ...table,
            views: updatedViews,
          }
        }
        return table
      },
    )

    setDetailedTableData(newData)
    await updateViewRecord({
      variables: {
        input: {
          id: airtableViewId,
          matchingView: matchData,
        },
      },
    })
  }

  const deleteViewMatch = async (airtableViewId: number) => {
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedViews: DetailedViewRecord[] = table.views.map(
            (view: DetailedViewRecord) => {
              if (view.id === airtableViewId) {
                const { matchingView, ...restOfView } = view
                return { ...restOfView }
              }
              return view
            },
          )

          return {
            ...table,
            views: updatedViews,
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await updateViewRecord({
      variables: {
        input: {
          id: airtableViewId,
          matchingView: null,
        },
      },
    })
  }

  const updateLocalStateAndGenerateInputForViews = (
    airtableViewIds: number[],
    matchData: MatchingBaserowViewType,
  ) => {
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === sortedDetailedTableData[selectedTableIndex].id) {
          const updatedViews: DetailedViewRecord[] = table.views.map(
            (view: DetailedViewRecord) => {
              if (airtableViewIds.includes(view.id)) {
                return {
                  ...view,
                  matchingView: matchData,
                }
              }
              return view
            },
          )

          return {
            ...table,
            views: updatedViews,
          }
        }
        return table
      },
    )

    setDetailedTableData(newData)

    return airtableViewIds.map((id) => ({
      id,
      matchingView: matchData,
    }))
  }

  const [updateFieldRecord] = useMutation<
    updateAirtableToBaserowMigrationField,
    updateAirtableToBaserowMigrationFieldVariables
  >(UPDATE_IMPORTER_FIELD, {
    onCompleted: (_data) => {
      toast.success('Updated')
    },
    refetchQueries: [
      {
        query: FindAirtableToBaserowImporterData,
        fetchPolicy: 'network-only',
      },
      {
        query: FindAirtableToBaserowRequest,
        variables: { id: migrationRequest.id },
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const [updateFieldRecords] = useMutation<
    updateAirtableToBaserowMigrationFields,
    updateAirtableToBaserowMigrationFieldsVariables
  >(UPDATE_IMPORTER_FIELDS, {
    onCompleted: (_data) => {
      toast.success('Updated')
    },
    refetchQueries: [
      {
        query: FindAirtableToBaserowImporterData,
        fetchPolicy: 'network-only',
      },
      {
        query: FindAirtableToBaserowRequest,
        variables: { id: migrationRequest.id },
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const [updateViewRecord] = useMutation<
    updateAirtableToBaserowMigrationView,
    updateAirtableToBaserowMigrationViewVariables
  >(UPDATE_IMPORTER_VIEW, {
    onCompleted: (_data) => {
      toast.success('Updated')
    },
    refetchQueries: [
      'FindAirtableToBaserowImporterData',
      'FindAirtableToBaserowRequest',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const [updateViewRecords] = useMutation<
    updateAirtableToBaserowMigrationViews,
    updateAirtableToBaserowMigrationViewsVariables
  >(UPDATE_IMPORTER_VIEWS, {
    onCompleted: (_data) => {
      toast.success('Updated')
    },
    refetchQueries: [
      'FindAirtableToBaserowImporterData',
      'FindAirtableToBaserowRequest',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  return (
    <>
      {[
        BASEROW_IMPORT_MATCH_TYPE.DETECTED,
        BASEROW_IMPORT_MATCH_TYPE.MANUAL,
        BASEROW_IMPORT_MATCH_TYPE.CREATE,
      ].includes(table.matchingTable?.matchType) ? (
        <>
          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            style={{ borderBottom: 'none' }}
            className="bg-white flex justify-between"
          >
            <Tab label={'Fields'} key={'fields'} />
            <Tab label={'Views'} key={'views'} />
          </Tabs>
          {selectedTabIndex === 0 && (
            <BaserowImporterComponentFieldList
              tableItem={table}
              handleFieldMatch={handleFieldMatch}
              deleteFieldMatch={deleteFieldMatch}
              handleFieldCreate={handleFieldCreate}
              handleFieldCreateAll={handleFieldCreateAll}
              handleFieldIgnore={handleFieldIgnore}
              tableData={sortedDetailedTableData}
            />
          )}
          {selectedTabIndex === 1 && (
            <BaserowImporterComponentViewList
              tableItem={table}
              handleViewMatch={handleViewMatch}
              deleteViewMatch={deleteViewMatch}
              handleViewCreate={handleViewCreate}
              handleViewCreateAll={handleViewCreateAll}
              handleViewIgnore={handleViewIgnore}
            />
          )}
        </>
      ) : [BASEROW_IMPORT_MATCH_TYPE.IGNORE].includes(
          table.matchingTable?.matchType,
        ) ? (
        <Box className={'pt-20 flex justify-center items-center'}>
          <EmptyData
            title={'Ignored Table'}
            description={
              'This table will be ignored and nore imported to Baserow'
            }
            icon={<MinusCircleIcon className={'w-20 h-20 text-gray-400'} />}
          />
        </Box>
      ) : (
        <Box className={'pt-20'}>
          <EmptyData
            title={'Select A Match Type'}
            description={'Select a match type to view fields and views'}
          />
        </Box>
      )}
    </>
  )
}

export default BaserowImporterSectionRight
