import * as React from 'react'

import { Metadata } from '@redwoodjs/web'

import BaserowImporterCell from 'src/components/BaserowImporter/BaserowImporterCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const BaserowImportPage = () => {
  return (
    <>
      <Metadata title="Baserow Import" description="Baserow Import" />
      <div className={'flex flex-col h-screen'}>
        <PageHeader title={'Baserow Import'} />
        <div className="flex flex-col h-full overflow-y-auto">
          <BaserowImporterCell />
        </div>
      </div>
    </>
  )
}

export default BaserowImportPage
