import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DetailedViewRecord } from 'api/src/common/baserowImporterTypes'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewSorting = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Accordion>
      <AccordionSummary
        className="border border-gray-300 border-solid rounded"
        expandIcon={<ChevronDownIcon className="w-5 h-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Sorting</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} className={'border p-2'}>
          <Stack spacing={1} direction={'row'}>
            <Typography>Sorting Currently </Typography>
            {enrichedData?.details?.lastSortsApplied?.shouldAutoSort ? (
              <Chip
                size="small"
                className="rounded text-sm bg-emerald-100 text-emerald-500 px-2 w-[10%] "
                label={'On'}
              />
            ) : (
              <Chip
                size="small"
                className="rounded text-sm bg-red-100 text-red-500 px-2 w-[20%] "
                label={'Off'}
              />
            )}
          </Stack>
          {enrichedData?.details?.lastSortsApplied?.sortSet?.map(
            (sort, index) => {
              return (
                <Stack key={index} spacing={1} direction={'row'}>
                  <Typography>Field</Typography>
                  <Chip
                    size="small"
                    className="rounded text-sm bg-blue-100 text-blue-500 px-2 w-[20%] "
                    label={sort.name}
                  />
                  <Typography>Sorted</Typography>
                  <Chip
                    size="small"
                    className="capitalize rounded text-sm bg-violet-100 text-violet-500 px-2 w-[20%] "
                    label={sort.ascending ? 'Ascending' : 'Descending'}
                  />
                </Stack>
              )
            },
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewSorting
