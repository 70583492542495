import React, { useEffect, useMemo, useState } from 'react'

import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { Box, Grid2 as Grid, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DetailedFieldRecord,
  DetailedTableRecord,
  MatchingBaserowFieldType,
} from 'api/src/common/baserowImporterTypes'

import BaserowImporterComponentFieldItem from 'src/components/BaserowImporter/BaserowImporterComponentFieldItem'

interface BaserowImporterTableComponentProps {
  tableItem: DetailedTableRecord
  handleFieldMatch: (airtableFieldId: number, baserowFieldId: number) => void
  deleteFieldMatch: (airtableFieldId: number) => void
  handleFieldCreate: (airtableFieldId: number) => void
  handleFieldCreateAll: (airtableTableId: number[]) => void
  handleFieldIgnore: (fieldId: number) => void
  tableData: DetailedTableRecord[]
}

const BaserowImporterComponentFieldList = ({
  tableItem,
  handleFieldMatch,
  deleteFieldMatch,
  handleFieldCreate,
  handleFieldCreateAll,
  handleFieldIgnore,
  tableData,
}: BaserowImporterTableComponentProps) => {
  const [unmatchedBaserowFields, setUnmatchedBaserowFields] = useState<
    MatchingBaserowFieldType[]
  >([])

  useEffect(() => {
    if (!tableItem?.matchingTable?.fields) {
      return
    }
    const unmatchedFields: MatchingBaserowFieldType[] = []
    for (const brField of tableItem.matchingTable.fields) {
      let matchFound = false
      for (const airField of tableItem.fields) {
        if (
          airField.matchingField &&
          airField.matchingField.id === brField.id
        ) {
          matchFound = true
          break
        }
      }
      if (!matchFound) {
        unmatchedFields.push(brField)
      }
    }
    setUnmatchedBaserowFields(unmatchedFields)
  }, [tableItem])

  const sortedFields: DetailedFieldRecord[] = useMemo(() => {
    const sortedData: DetailedFieldRecord[] = (
      tableItem.fields as DetailedFieldRecord[]
    )
      ?.slice()
      .sort((a: DetailedFieldRecord, b: DetailedFieldRecord) =>
        a.name.localeCompare(b.name),
      )
    return sortedData
  }, [tableItem])

  return (
    <Box className={'border-t-2 bg-white p-5'}>
      {tableItem.matchingTable && (
        <Grid container spacing={2}>
          <Grid size={{ xs: 5 }}>
            <Typography fontWeight={'bold'} className={'text-xs'}>
              Field Name
            </Typography>
          </Grid>

          <Grid size={{ xs: 5 }}>
            <Typography fontWeight={'bold'} className={'text-xs'}>
              Matching Field
            </Typography>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <Typography fontWeight={'bold'} className={'text-xs'}>
                Actions
              </Typography>
              <Tooltip title={'Create all fields'}>
                <IconButton
                  onClick={() => {
                    handleFieldCreateAll(
                      tableItem.fields.map((field) => field.id),
                    )
                  }}
                >
                  <CloudArrowUpIcon className={'h-5 w-5'} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {sortedFields.map((field) => (
            <BaserowImporterComponentFieldItem
              key={field.id}
              fieldItem={field}
              handleFieldMatch={handleFieldMatch}
              deleteFieldMatch={deleteFieldMatch}
              unmatchedBaserowFields={unmatchedBaserowFields}
              handleFieldCreate={handleFieldCreate}
              handleFieldIgnore={handleFieldIgnore}
              tableItem={tableItem}
              tableData={tableData}
            />
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default BaserowImporterComponentFieldList
