import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DetailedFieldRecord,
  DetailedTableRecord,
} from 'api/src/common/baserowImporterTypes'

import { returnOptionChip } from 'src/components/BaserowImporter/helpers'
import {
  returnFieldOptions,
  splitByCapitals,
} from 'src/components/BaserowImporter/utils'
import Modal from 'src/components/Modal/Modal'

interface BaserowImporterViewInformationModalProps {
  selectedRecord: DetailedFieldRecord
  openModal: boolean
  handleCloseModal: () => void
  tableData: DetailedTableRecord[]
  tableItem: DetailedTableRecord
}

const BaserowImporterModalFieldDetails = ({
  selectedRecord,
  openModal,
  handleCloseModal,
  tableData,
  tableItem,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      title={
        selectedRecord?.name
          ? 'Details for field: ' + selectedRecord?.name
          : 'No field selected'
      }
      footerVisible={true}
      onConfirm={handleCloseModal}
      cancelButtonVisible={false}
      confirmText={'close'}
    >
      <Stack spacing={2} className={'p-5'}>
        <Stack direction={'row'} spacing={2}>
          <Typography fontWeight={'bold'} className={'w-[20%]'}>
            Field Name:
          </Typography>
          <Typography className={'w-[80%]'}>{selectedRecord?.name}</Typography>
        </Stack>

        <Stack direction={'row'} spacing={2}>
          <Typography fontWeight={'bold'} className={'w-[20%]'}>
            Field Description:
          </Typography>
          <Typography className={'w-[80%]'}>
            {selectedRecord?.details?.description}
          </Typography>
        </Stack>

        <Stack direction={'row'} spacing={2}>
          <Typography fontWeight={'bold'} className={'w-[20%]'}>
            Field Type:
          </Typography>
          <Typography className={'w-[80%]'}>
            {splitByCapitals(selectedRecord?.type)}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <Typography fontWeight={'bold'} className={'w-[20%]'}>
            Field Options:
          </Typography>
          <Stack spacing={1} className={'w-[80%]'}>
            {returnOptionChip(
              returnFieldOptions(selectedRecord, tableItem, tableData),
            )}
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default BaserowImporterModalFieldDetails
