import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  BASEROW_IMPORT_MATCH_TYPE,
  BaserowTableSchema,
  DetailedFieldRecord,
  DetailedTableRecord,
  MatchingBaserowTableType,
} from 'api/src/common/baserowImporterTypes'
import {
  updateAirtableToBaserowMigrationTable,
  updateAirtableToBaserowMigrationTableVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import BaserowImporterComponentTableDetails from 'src/components/BaserowImporter/BaserowImporterComponentTableDetails'
import BaserowImporterComponentTableMatch from 'src/components/BaserowImporter/BaserowImporterComponentTableMatch'
import { CellMigrationType } from 'src/components/BaserowImporter/BaserowImporterRecordCell/BaserowImporterRecordCell'
import { UPDATE_IMPORTER_TABLE } from 'src/components/BaserowImporter/utils'

interface BaserowImporterMainSectionProps {
  selectedTableIndex: number
  setDetailedTableData: Dispatch<SetStateAction<DetailedTableRecord[]>>
  sortedDetailedTableData: DetailedTableRecord[]
  selectedRequest: CellMigrationType
}

const BaserowImporterSectionRight = ({
  selectedTableIndex,
  setDetailedTableData,
  sortedDetailedTableData,
  selectedRequest,
}: BaserowImporterMainSectionProps) => {
  const [unmatchedTables, setUnmatchedTables] = useState<BaserowTableSchema[]>(
    [],
  )
  const handleTableCreate = async (airtableTableId: number) => {
    const matchData: MatchingBaserowTableType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.CREATE,
    }
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === airtableTableId) {
          return {
            ...table,
            matchingTable: matchData,
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await handleUpdateTable(airtableTableId, matchData)
  }

  const handleTableIgnore = async (airtableTableId: number) => {
    const matchData: MatchingBaserowTableType = {
      matchType: BASEROW_IMPORT_MATCH_TYPE.IGNORE,
    }
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === airtableTableId) {
          return {
            ...table,
            matchingTable: { matchType: BASEROW_IMPORT_MATCH_TYPE.IGNORE },
          }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await handleUpdateTable(airtableTableId, matchData)
  }

  const deleteTableMatch = async (airtableTableId: number) => {
    const newData: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table) => {
        if (table.id === airtableTableId) {
          const updatedFields: DetailedFieldRecord[] = table.fields.map(
            (field) => {
              const { matchingField, ...restOfField } =
                field as DetailedFieldRecord
              return { ...restOfField }
            },
          )
          const { matchingTable, ...restOfTable } = table
          return { ...restOfTable, fields: updatedFields }
        }
        return table
      },
    )
    setDetailedTableData(newData)
    await handleUpdateTable(airtableTableId, null)
  }

  const setTableMatch = async (
    airtableTableId: number,
    baserowTableId: number,
  ) => {
    const matchingBaserowTable: BaserowTableSchema = (
      selectedRequest?.baserowSchema as Record<string, any>
    )?.find((table) => table.id === baserowTableId)
    const dataCopy: DetailedTableRecord[] = sortedDetailedTableData.map(
      (table: DetailedTableRecord) => {
        if (table.id === airtableTableId) {
          return {
            ...table,
            matchingTable: {
              ...matchingBaserowTable,
              matchType: BASEROW_IMPORT_MATCH_TYPE.MANUAL,
            },
          }
        }
        return table
      },
    )
    const tableToUpdate: DetailedTableRecord = dataCopy.find(
      (table: DetailedTableRecord) => table.id === airtableTableId,
    )
    const matchingTable: MatchingBaserowTableType = {
      ...matchingBaserowTable,
      matchType: BASEROW_IMPORT_MATCH_TYPE.MANUAL,
    }
    setDetailedTableData(dataCopy)
    await handleUpdateTable(tableToUpdate.id, matchingTable)
  }

  const handleUpdateTable = async (
    id: number,
    matchData: MatchingBaserowTableType,
  ) => {
    await updateTableRecord({
      variables: {
        input: {
          id: id,
          matchingTable: matchData,
        },
      },
    })
  }

  const [updateTableRecord] = useMutation<
    updateAirtableToBaserowMigrationTable,
    updateAirtableToBaserowMigrationTableVariables
  >(UPDATE_IMPORTER_TABLE, {
    onCompleted: (_data) => {
      toast.success('Updated')
    },
    refetchQueries: [
      'FindAirtableToBaserowImporterData',
      'FindAirtableToBaserowRequest',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const getUnmatchedAirtableTables = () => {
    const matchedAirtableIds: number[] = selectedRequest?.migrationData
      ?.filter((table) => table.matchingTable)
      ?.map((table) => (table.matchingTable as Record<string, any>).id)
    const unmatchedAirtableTables: BaserowTableSchema[] = (
      selectedRequest?.baserowSchema as Record<string, any>
    )?.filter((airtable) => !matchedAirtableIds.includes(airtable.id))
    return unmatchedAirtableTables
  }

  useEffect(() => {
    if (selectedRequest) {
      const unmatchedTableList: BaserowTableSchema[] =
        getUnmatchedAirtableTables()
      setUnmatchedTables(unmatchedTableList)
    }
  }, [selectedRequest])

  return (
    <>
      {sortedDetailedTableData?.length > 0 && (
        <>
          {sortedDetailedTableData?.map((table: DetailedTableRecord, index) => (
            <div key={table.id}>
              {selectedTableIndex === index && (
                <>
                  <BaserowImporterComponentTableMatch
                    tableItem={table}
                    deleteTableMatch={deleteTableMatch}
                    setTableMatch={setTableMatch}
                    unmatchedTables={unmatchedTables}
                    handleTableCreate={handleTableCreate}
                    handleTableIgnore={handleTableIgnore}
                  />
                  <BaserowImporterComponentTableDetails
                    sortedDetailedTableData={sortedDetailedTableData}
                    selectedTableIndex={selectedTableIndex}
                    setDetailedTableData={setDetailedTableData}
                    table={table}
                    migrationRequest={selectedRequest}
                  />
                </>
              )}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default BaserowImporterSectionRight
